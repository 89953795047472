import { Injectable } from '@angular/core';
import { CurrentUser } from '@interacta-shared/data-access-auth';

@Injectable({ providedIn: 'root' })
export class GoogleAnalyticsService {
    private token?: string;

    private userProps: {
        area_id?: number | null;
        area_name?: string | null;
        business_unit_id?: number | null;
        business_unit_name?: string | null;
        plant_id?: number;
        plant_base_url?: string;
    } = {};

    init(token: string, plantId: number, plantBaseUrl: string): void {
        this.token = token;

        this.userProps = {
            plant_id: plantId,
            plant_base_url: plantBaseUrl,
        };

        gtag('js', new Date());

        gtag('config', token, {
            send_page_view: false,
        });

        gtag('set', 'user_properties', this.userProps);

        console.log('ACTIVE ANALYTICS');
    }

    sendScreenView(page: string): void {
        if (this.token) gtag('event', 'screen_view', { screen_name: page });
    }

    setUserProperties(user: CurrentUser | null): void {
        this.userProps = {
            ...this.userProps,
            area_id: user?.area?.id ?? null,
            area_name: user?.area?.name ?? null,
            business_unit_id: user?.businessUnit?.id ?? null,
            business_unit_name: user?.businessUnit?.name ?? null,
        };

        gtag('set', 'user_properties', this.userProps);
    }
}
